main {
  position: relative;
  z-index: 1;
  max-width: 60ch;
  margin: auto;
  font-size: 1.2em;
  padding: 2em 1em;
  padding-bottom: 0;
}

header {
  text-align: center;
}

.main {
  text-align: center;
}

.details {
  margin-top: 0.5em;
  max-width: 60ch;

  > * + * {
    margin-top: 0.5em;
  }
}

ul {
  margin: 0;

  &.stats {
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      min-height: 1em;
      list-style-type: none;
      transform: scaleY(0.8);

      b,
      span {
        background: white;
        color: black;
        min-width: 9ch;
        display: inline-flex;
        justify-content: center;
        border: 2px solid black;
      }

      b + span {
        border-left: 0;
      }

      b {
        background: black;
        color: white;
      }
    }
  }
}

.character {
  margin: 1em 0;
  background: white;
  color: black;
  padding: 5px;

  &-wrapper {
    border: 3px dashed black;
    padding: 0.75em;
  }
}

footer {
  margin-bottom: 1em;
}

@media print {
  header,
  footer {
    display: none;
  }

  ul.stats li {
    b,
    span {
      color: black;
      border: 3px solid black;
    }
  }

  .character {
    padding: 0;

    &-wrapper {
      padding: 0;
      border: 0;
    }
  }
}
